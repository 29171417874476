<template>
  <div>
    <b-form inline>
      <label>Interval</label>
      <select
        v-model="selectedPeriodicity"
        @change="periodicitySelected"
        class="form-control border-bottom-1 border-left-0 border-right-0 border-top-0 rounded-0"
      >
        <b-form-select-option
          v-for="option in allowedPeriodicities"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}</b-form-select-option
        >
      </select>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    allowedPeriodicities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPeriodicity: "daily",
    };
  },
  watch: {
    allowedPeriodicities(newVal, oldVal) {
      if (newVal !== oldVal) {
        console.log("allowedPeriodicities:", newVal);
        // Actualizar el valor de "selectedPeriodicity" a "daily" cada vez que se detecte un cambio en "allowedPeriodicities"
        this.selectedPeriodicity = "daily";
        // Emitir el evento de cambio en "selectedPeriodicity"
        this.periodicitySelected();
      }
    },
  },

  methods: {
    periodicitySelected() {
      this.$emit("periodicity-updated", this.selectedPeriodicity);
    },
  },
};
</script>
<style scoped></style>
