<template>
  <ShadowCard :param="{ name: 'Advanced Chart' }">
    <CRow class="mb-3">
      <CCol class="col-auto">
        <timeFrameSelectorWidget @interval-updated="updateDates" />
      </CCol>
      <CCol class="col-auto">
        <periodicitySelectorWidget
          @periodicity-updated="updatePeriodicity"
          v-model="selectedPeriodicity"
          :allowedPeriodicities="allowedPeriodicities" />
      </CCol>
      <CCol class="col-auto ml-auto align-self-center">
        <nightModeAdvancedChart v-model="night" :colorProps="colorProps" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <b-overlay :show="loading" :variant="'secondary'" :opacity="0.5">
          <advancedChart :colorProps="colorProps" />
        </b-overlay>
      </CCol>
    </CRow>
    <div class="row">
      <BackButton />
    </div>
  </ShadowCard>
</template>

<script>
import ShadowCard from "@/presentationalComponents/ShadowCard.vue";
import { getSecurityHistoricalPrice } from "@/services/api.js";
import advancedChart from "./advancedChart.vue";
import timeFrameSelectorWidget from "./timeFrameSelectorWidget.vue";
import periodicitySelectorWidget from "./periodicitySelectorWidget.vue";
import nightModeAdvancedChart from "./nightModeAdvancedChart.vue";
import BackButton from "@/components/buttons/backButton.vue";

export default {
  name: "advancedChartView",
  components: {
    ShadowCard,
    advancedChart,
    timeFrameSelectorWidget,
    periodicitySelectorWidget,
    nightModeAdvancedChart,
    BackButton,
  },

  data() {
    return {
      fromDate: "",
      toDate: "",
      loading: false,
      selectedPeriodicity: "",
      allowedPeriodicities: [],
      //Night Mode

      night: true,
      colorProps: {
        colorBack: "#121826",
        colorGrid: "#2f3240",
        colorText: "#dedddd",
        colorTitle: "white",
      },
    };
  },

  methods: {
    getDaysDifference(fromDate, toDate) {
      // Convertir los argumentos numéricos a objetos de fecha
      const fromDateObj = new Date(
        fromDate.toString().substring(0, 4),
        parseInt(fromDate.toString().substring(4, 6)) - 1,
        fromDate.toString().substring(6)
      );

      const toDateObj = new Date(
        toDate.toString().substring(0, 4),
        parseInt(toDate.toString().substring(4, 6)) - 1,
        toDate.toString().substring(6)
      );

      const oneDay = 24 * 60 * 60 * 1000; // Cantidad de milisegundos en un día
      const timeDifference = toDateObj.getTime() - fromDateObj.getTime(); // Resta las fechas en milisegundos para obtener la diferencia en milisegundos
      const cantDias = Math.floor(timeDifference / oneDay); // Divide la diferencia en milisegundos por la cantidad de milisegundos en un día y redondea hacia abajo

      return cantDias;
    },

    getAllowedPeriodicities(fromDate, toDate) {
      const cantDias = this.getDaysDifference(fromDate, toDate);

      switch (true) {
        case cantDias > 360:
          return [
            { value: "daily", label: "1D" },
            { value: "weekly", label: "1W" },
            { value: "monthly", label: "1M" },
            { value: "quarterly", label: "3M" },
            { value: "semiannually", label: "6M" },
          ];
        case cantDias > 180:
          return [
            { value: "daily", label: "1D" },
            { value: "weekly", label: "1W" },
            { value: "monthly", label: "1M" },
          ];
        case cantDias > 90:
          return [
            { value: "daily", label: "1D" },
            { value: "weekly", label: "1W" },
            { value: "monthly", label: "1M" },
          ];
        case cantDias > 30:
          return [
            { value: "daily", label: "1D" },
            { value: "weekly", label: "1W" },
          ];
        case cantDias > 7:
          return [
            { value: "daily", label: "1D" },
            { value: "weekly", label: "1W" },
          ];
        case cantDias > 1:
          return [{ value: "daily", label: "1D" }];
        default:
          return [
            { value: "daily", label: "1D" },
            { value: "weekly", label: "1W" },
            { value: "monthly", label: "1M" },
            { value: "quarterly", label: "3M" },
            { value: "semiannually", label: "6M" },
            { value: "yearly", label: "1Y" },
          ];
      }
    },

    updatePeriodicity(selectedPeriodicity) {
      this.selectedPeriodicity = selectedPeriodicity; // Actualizar la variable de datos del componente padre con la periodicidad seleccionada
      this.updateChartData(); // Llamar al método para actualizar los datos del gráfico
    },

    updateDates(fromDate, toDate) {
      this.loading = true;
      this.fromDate = new Date(fromDate)
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "");
      this.toDate = new Date(toDate)
        .toISOString()
        .slice(0, 10)
        .replace(/-/g, "");
      this.allowedPeriodicities = this.getAllowedPeriodicities(
        this.fromDate,
        this.toDate
      );
      //this.updateChartData();
    },

    updateChartData() {
      this.loading = true;
      getSecurityHistoricalPrice(
        this.$store.state.assetIdentifier,
        this.fromDate,
        this.toDate,
        this.selectedPeriodicity
      ).then((response) => {
        this.$store.commit("SET_SECURITY_HISTORICALPRICE", response.data);
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.secondary-button {
  color: #3c4b64;
  border: #3c4b64;
  background-color: white;
  min-width: 180px;
  background: rgb(255, 255, 255);
  padding-left: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
}
</style>
