<template>
  <div ref="chartContainer">
    <trading-vue
      :key="`advanced-chart-${new Date().getTime()}`"
      v-if="chart"
      :data="chart"
      :overlays="overlays"
      :extensions="ext"
      :legend-buttons="['display', 'settings', 'up', 'down', 'add', 'remove']"
      :title-txt="assetName"
      :color-back="colorProps.colorBack"
      :color-grid="colorProps.colorGrid"
      :color-text="colorProps.colorText"
      :color-title="colorProps.colorTitle"
      :toolbar="true"
      :chart-config="{ DEFAULT_LEN: ohlcvLength }"
      :width="chartWidth"
      :height="chartHeight"
    />
  </div>
</template>

<script>
import { DataCube } from "trading-vue-js";
import TradingVue from "trading-vue-js";
import Overlays from "tvjs-overlays";
import XP from "tvjs-xp";

export default {
  name: "AdvancedChart",
  components: { TradingVue },

  data() {
    return {
      chart: null,
      assetName: "",
      night: true,
      ohlcvLength: "",
      chartWidth: null,
      chartHeight: null,
      overlays: Object.values(Overlays),
      ext: Object.values(XP),
    };
  },

  props: {
    colorProps: {
      type: Object,
      required: true,
    },
  },

  watch: {
    securityHistoricalPrice: {
      immediate: true,
      handler() {
        this.loadData();
      },
    },
  },

  mounted() {
    const resizeObserver = new ResizeObserver(this.onChartResize);
    resizeObserver.observe(this.$refs.chartContainer);
  },

  beforeDestroy() {
    const resizeObserver = new ResizeObserver(this.onChartResize);
    resizeObserver.unobserve(this.$refs.chartContainer);
  },

  methods: {
    onChartResize() {
      this.chartWidth = this.$refs.chartContainer.clientWidth;
      this.chartHeight = this.chartWidth * (5 / 10);
    },
    loadData() {
      var tempOhlcv = [];

      for (const date in this.securityHistoricalPrice.data) {
        for (const identifier in this.securityHistoricalPrice.data[date]) {
          const HistoricalPriceObj =
            this.securityHistoricalPrice.data[date][identifier];

          const assetName = HistoricalPriceObj[0].metadata.security.name;

          tempOhlcv.push([
            Date.parse(date),
            HistoricalPriceObj[0].openPrice,
            HistoricalPriceObj[0].highPrice,
            HistoricalPriceObj[0].lowPrice,
            HistoricalPriceObj[0].closePrice,
            HistoricalPriceObj[0].metadata.priceData.volume,
          ]);
          this.assetName = assetName;
        }
      }

      this.chart = new DataCube({
        chart: {
          type: "Candles",
          data: tempOhlcv, // Pass the new data array to the chart
          indexBased: true,
        },
        onchart: [
          { name: "SMA 50", type: "SMA", data: [], settings: { length: 50 } },
          { name: "SMA 100", type: "SMA", data: [], settings: { length: 100 } },
          { name: "SMA 200", type: "SMA", data: [], settings: { length: 200 } },
        ],
/*         offchart: [
          { name: "RSI 14", type: "RSI", data: [], settings: { length: 14 } },
        ], */
      });

      this.ohlcvLength = tempOhlcv.length;
    },
  },

  computed: {
    securityHistoricalPrice() {
      return this.$store.state.securityHistoricalPrice;
    },
  },
};
</script>
