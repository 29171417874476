<template>
  <div>
    <b-form inline>
      <label>Date Range</label>
      <select
        v-model="selectedDateRange"
        @change="selectedTimeFrame"
        class="form-control border-bottom-1 border-left-0 border-right-0 border-top-0 rounded-0"
      >
        <b-form-select-option value="1M">1M</b-form-select-option>
        <b-form-select-option value="3M">3M</b-form-select-option>
        <b-form-select-option value="6M">6M</b-form-select-option>
        <b-form-select-option value="YTD">YTD</b-form-select-option>
        <b-form-select-option value="1Y">1Y</b-form-select-option>
        <b-form-select-option value="3Y">3Y</b-form-select-option>
        <b-form-select-option value="5Y">5Y</b-form-select-option>
      </select>
    </b-form>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "timeFrameSelectorWidget",
  data() {
    return {
      selectedDateRange: "1Y",
      fromDate: "",
      toDate: "",
    };
  },
  created() {
    this.selectedTimeFrame();
  },

  watch: {
    selectedDateRange() {
      this.selectedTimeFrame();
    },
  },

  methods: {
    selectedTimeFrame() {
      switch (this.selectedDateRange) {
        case "1M":
          this.fromDate = moment().subtract(1, "month").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          break;
        case "3M":
          this.fromDate = moment().subtract(3, "month").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");

          break;
        case "6M":
          this.fromDate = moment().subtract(6, "month").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          break;
        case "YTD":
          this.fromDate = moment().startOf("year").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          break;
        case "1Y":
          this.fromDate = moment().subtract(1, "year").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          break;
        case "3Y":
          this.fromDate = moment().subtract(3, "year").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          break;
        case "5Y":
          this.fromDate = moment().subtract(5, "year").format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          break;
        default:
          break;
      }
      this.$emit("interval-updated", this.fromDate, this.toDate);
    },
  },
};
</script>
