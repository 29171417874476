<template>
  <b-form inline>
    <b-form-checkbox v-model="night" switch>Night Mode</b-form-checkbox>
  </b-form>
</template>

<script>
export default {
  props: {
    night: {
      type: Boolean,
      required: false,
      default: true,
    },
    colorProps: {
      type: Object,
      required: true,
    },
  },
  watch: {
    night: function (value) {
      this.updateColors(value);
    },
  },
  created() {
    this.updateColors(this.night);
  },
  methods: {
    updateColors(night) {
      if (night) {
        this.colorProps.colorBack = "#121826";
        this.colorProps.colorGrid = "#2f3240";
        this.colorProps.colorText = "#dedddd";
        this.colorProps.colorTitle = "white";
      } else {
        this.colorProps.colorBack = "#FFFFFF";
        this.colorProps.colorGrid = "#eee";
        this.colorProps.colorText = "#363636";
        this.colorProps.colorTitle = "black";
      }
    },
  },
};
</script>
